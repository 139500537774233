import React, { useEffect } from "react";

export default function TermsOfUse() {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  return (
    <div className="bg-black min-h-screen">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="space-y-8 text-gray-300">
          <div className="space-y-2">
            <h1 className="text-4xl font-bold text-white">Terms of Use</h1>
            <p className="text-gray-400">
              Our Terms of Use are effective as of January 19th, 2024
            </p>
          </div>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">Notice</h2>
            <p>
              Welcome to Parlaye! These Terms of Use ("Terms") govern your
              access to and use of the Parlaye mobile application and website
              (collectively, the "App") provided by Parlaye Inc. ("Parlaye,"
              "we,", "us", "our(s)"). Please read these Terms of Use carefully
              before using the Parlaye Inc. ("Parlaye") platform. These Terms
              contain important information that affects your legal rights and
              obligations. It is your responsibility to review and understand
              the terms before proceeding. If you do not agree to these Terms of
              Use, you may not access or use the Parlaye platform.
            </p>
            <p>
              These Terms of Use include a binding arbitration provision and a
              class action waiver that may impact your legal rights and
              remedies. It is essential that you carefully review the section
              titled Dispute Resolution below to understand the implications of
              these provisions. If you do not wish to be subject to arbitration
              on a retroactive basis, you may have the option to opt out of the
              arbitration provision within thirty (30) days. Details regarding
              the opt-out process can be found in the section titled Statutes
              and Limitations.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              1 Acceptance of the Terms of Use and Code of Conduct
            </h2>
            <p>
              By accessing or using the App, or utilizing our services, you, the
              User (collectively "User(s)”, “you", “your(s)”), acknowledge that
              you have carefully read, understood, and expressly agree to be
              legally bound by these Terms. Furthermore, you represent and
              warrant that you are of legal age to enter into a legally binding
              agreement. These Terms apply to all Users of the App, including
              but not limited to registered users, visitors, and individuals
              browsing or interacting with the App in any manner. If you do not
              agree with any of the terms set out in these Terms do not use the
              App provided by Parlaye Inc and remove the App from all of your
              devices.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              2 Modifications
            </h2>
            <p>
              Parlaye reserves the right to update or modify these Terms of Use
              at any time without prior notice. Any changes to the Terms of Use
              will be effective immediately upon posting the updated version
              within the App. It is your responsibility to review these Terms of
              Use periodically for any updates or modifications. Your continued
              use of the App after the posting of any changes constitutes your
              acceptance of such changes.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              3 Geographic Restrictions
            </h2>
            <p>
              The App is intended for use only by persons located in Canada and
              the United States of America. The types of contests you may
              participate in may be governed by your place of residence in
              Canada and in the United States of America. Our geolocation
              validation process will guide your usage. It is your
              responsibility to adhere to the location restrictions we provide.
              The App is not intended for use in any other jurisdictions. If you
              access the App from outside any of the jurisdictions above, and
              circumvent the restrictions we have put into place, you do so at
              your own risk and you are then responsible for compliance with
              local laws of your jurisdiction.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              4 Application License
            </h2>
            <p>
              Conditioned upon your compliance with these Terms of Use, Parlaye
              affords you a limited, non-exclusive, non-transferable, revocable
              license to download, install, and use the App on a compatible
              device that you own or control, solely for your personal,
              non-commercial use. The license granted to you does not include
              any right to modify, reverse engineer, decompile, copy,
              disassemble, or create derivative works of the App, except to the
              extent expressly permitted by applicable law. You agree that you
              will use the App only for lawful purposes and in accordance with
              these Terms of Use. You shall not use the App in any manner that
              infringes, violates, or misappropriates the rights of any third
              party, or that is prohibited by any applicable laws or
              regulations. Parlaye reserves the right to terminate or suspend
              your license to use the App at any time and for any reason,
              without prior notice or liability.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              5 Privacy and Data Collection
            </h2>
            <p>
              Parlaye Inc. is committed to protecting your privacy. The
              collection, use, and disclosure of personal information through
              the Parlaye Inc. iOS app areis governed by our Privacy Policy . By
              using the app, you consent to the collection, storage, and
              processing of your personal information as outlined in the Privacy
              Policy.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              6 Parlaye Account Eligibility
            </h2>
            <p>
              Users may browse the Parlaye mobile application ("App") without
              having an account, allowing them to explore the features and
              content available. However, please note that certain
              functionalities, such as entering paid or free contests, will
              require a registered account. To access all features and
              participate in contests offered within the App, you must create a
              Parlaye account and provide accurate and complete information
              during the registration process. If you, the User, wishes to enter
              both paid and free contests, you will be required to submit
              additional information that will help us determine your
              eligibility. You must enable location services. Failure to provide
              Parlaye Inc., access to the location of the device being used to
              access the App will exclude you from the App entirely.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              6.1 Additional Eligibility for Paid Contests
            </h2>
            <p>
              Eligibility for participation in paid contests is contingent upon
              several factors delineated herein. These factors are predicated
              upon user-specific information, as well as the jurisdiction, be it
              a State, Province, or Territory, in which you are physically
              situated when accessing the application, and the corresponding
              legislation governing the entry into paid Fantasy Sports contests.
              Notably, certain States, Provinces, or Territories mandate that
              Fantasy operators obtain registration and licensure to facilitate
              the provision of paid contests. If you are accessing the App
              within one of these jurisdictions or anywhere else where Parlaye
              lacks the requisite license, you will not be able to access paid
              contests, and shall be restricted only to the free-to-use
              contests. By depositing or participating in a paid contest, you
              explicitly acknowledge and confirm the accuracy of the information
              below:
            </p>
            <div className="space-y-2 pl-4">
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  You shall consistently adhere to these Terms and all other
                  agreements between yourself and Parlaye.
                </li>
                <li>
                  You warrant that you have attained the age of at least 18
                  years or have reached the minimum age required by the
                  applicable jurisdiction for legal usage of the App.
                </li>
                <li>
                  You declare that you are a citizen or permanent resident of
                  either Canada or the United States of America, possessing a
                  valid address in either country.
                </li>
                <li>
                  At the time of deposit, withdrawal, or contest entry, you
                  shall be physically situated within an eligible jurisdiction
                  in Canada or the United States of America.
                </li>
                <li>
                  You confirm that you are not an employee of any other fantasy
                  sports operator.
                </li>
                <li>
                  You affirm that you are not subject to backup withholding tax
                  for one of the following reasons: (1) you hold an exemption
                  from backup withholding, or (2) you have not received any
                  notification from the Internal Revenue Service (IRS) or Canada
                  Revenue Agency (CRA) indicating that you are subject to backup
                  withholding due to the non-reporting of interest or dividends,
                  or (3) the IRS or CRA has explicitly informed you that you are
                  exempt from backup withholding obligations.
                </li>
                <li>
                  You warrant that you are not listed on any prohibited lists
                  within Canada or the United States.
                </li>
                <li>
                  You acknowledge that you are the sole operator of your User
                  account and may only possess and operate a single account.
                </li>
                <li>
                  If you are a contractor, employee, officer or director of
                  Parlaye you are only using paid contests to test new features
                  and functionality on the app. For quality assurance purposes,
                  testing paid contests is restricted to the following
                  conditions: Paid Contests are private and played against
                  another contractor, employee, officer or director of Parlaye.
                </li>
                <li>
                  You confirm that you are not an immediate family member or
                  share an address with a contractor, employee, officer, or
                  director of Parlaye.
                </li>
                <li>
                  You affirm that you do not possess access to non-public
                  confidential information related to contests.
                </li>
                <li>
                  You declare that you are neither a professional nor an amateur
                  athlete capable of influencing the outcome of a contest hosted
                  by Parlaye through your personal performance.
                </li>
                <li>
                  You confirm that you are not a coach, owner, sports team
                  employee, or agent capable of influencing the outcome of a
                  contest hosted by Parlaye.
                </li>
              </ul>
            </div>
            <p>
              If Parlaye determines, at any given time, that you fail to satisfy
              or no longer fulfil all of the eligibility requirements
              highlighted above, you will lose your eligibility to use the App.
              If Parlaye is unable to verify your eligibility for participation
              in paid contests, we retain the right to suspend or terminate any
              or all of your accounts, as well as revoke any prizes you may have
              acquired through the App. Furthermore, Parlaye reserves the right
              to impose restrictions on your ability to withdraw from the App
              should you fail to sustain eligibility.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              7 Password Security
            </h2>
            <p>
              Upon registration of a Parlaye account within the App, it is
              imperative that you provide a valid email address and establish a
              username and password. As the User, it is your responsibility to
              maintain the utmost confidentiality of your password and bear
              liability for any losses incurred as a result of unauthorized
              usage of your account. If you suspect or become aware of any
              unauthorized activity or security breach concerning your account,
              you agree to promptly notify Parlaye of such occurrences.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">8 Contests</h2>
            <p>
              Parlaye provides users with the opportunity to engage in
              peer-to-peer contests, encompassing both free and paid options,
              through the App. In accordance with these Terms, you must meet
              specific eligibility criteria in order to participate in any form
              of contest.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              8.1 Contests - Game of Skill
            </h2>
            <p>
              Parlaye operates as a game of skill, wherein winners are
              determined based on the objective criteria explicitly outlined in
              the rules governing each respective contest. Within each contest,
              success is contingent upon the application of one's expertise. The
              accumulation of points through the accurate prediction of
              professional athletes' performances remains the primary
              determinant of success in a contest. It is important to note that
              the App or any services provided by Parlaye must not be employed
              for any illicit or unlawful forms of gambling or sports betting
              activities.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              8.2 Contest - Entry Fees & Prizes
            </h2>
            <p>
              Parlaye extends to you, the User, the opportunity to partake in
              paid contests, whereby prizes are awarded based on your
              performance relative to other participants within the same
              contest. The nature and magnitude of the prizes are contingent
              upon the specific type of contest you have elected to participate
              in. Should your contest entry not be matched by its start time,
              you will receive a full refund for the contest entry and your
              contest will be considered cancelled.
            </p>
            <p>
              If you are not in an eligible location to enter paid contest, you
              will not have access to these features, but you can still partake
              in free- to- enter contests. Free- to- enter contests do not
              reward prizes of monetary value.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              8.3 Contest - Live Statistics
            </h2>
            <p>
              Parlaye offers you, the User, tools to track your performance in
              real time within both paid and free contests. Live statistics
              viewable on the App are procured from third party providers and
              Parlaye is not responsible for their accuracy.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              8.4 Contest - Results
            </h2>
            <p>
              Upon the conclusion of a contest, you have the ability to verify
              your performance in both paid and free contests through the App.
              Notably, player-specific game statistics, which hold significance
              in evaluating your contest performance, are obtained from
              third-party providers. It is essential to recognize that Parlaye
              assumes no responsibility for the accuracy of these statistics.
              Additionally, please acquaint yourself with the following rules,
              which shall be applicable in particular circumstances:
            </p>
            <div className="space-y-2 pl-4">
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  If a scheduled sports game is postponed to a later time in the
                  same day, the contest associated with that game will resume
                  once the final player prediction can be accurately evaluated.
                  The contest end time will be adjusted to accommodate the new
                  game time. Participants' selected players from the postponed
                  game will only accrue points for their performance in the
                  rescheduled game. If the rescheduled game occurs on a
                  different day than the original, the game is to be considered
                  cancelled.
                </li>
                <li>
                  If a player selected as part of a contest does not participate
                  in any part of the scheduled game, whether due to game
                  cancellation, player non-participation, or any other
                  unforeseen circumstance, no refunds will be issued. The
                  contest will proceed as scheduled, and participants must rely
                  on the performance of the remaining players in their contest
                  to accumulate points and compete against their opponent(s). We
                  recommend that participants stay updated with game schedules
                  and player statuses to make informed decisions when deciding
                  on players to include their contests.
                </li>
                <li>
                  If a player sustains an injury during a game, and said player
                  was selected in a contest, the statistics accumulated by the
                  player before the injury shall be utilized to determine the
                  accuracy of predictions.
                </li>
              </ul>
            </div>
            <p>
              Should you, as the user, suspect any inaccuracies in the Contest
              Results or player results displayed on the App, we kindly request
              that you contact us promptly at support@parlaye.com.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9 Disqualification and Cancellation
            </h2>
            <p>
              In the event that Parlaye determines, at any point, that you do
              not meet the eligibility requirements or are no longer eligible to
              use the App, we reserve the right to close your account and
              require you to forfeit any profits or winnings associated with
              your account. Parlaye also reserves the right to close your
              account if you fail to comply with any of these Terms.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9.1 Eligibility Evaluation{" "}
            </h2>
            <p>
              Parlaye retains the discretion to assess and evaluate your
              eligibility to use the App based on factors including, but not
              limited to, your age, jurisdiction, compliance with applicable
              laws, and adherence to the terms and conditions set forth in these
              Terms.
            </p>
          </section>

          {/* start */}

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9.2 Account Closure
            </h2>
            <p>
              If Parlaye determines that you are ineligible or no longer
              eligible to use the App, or have otherwise violated these Terms,
              we reserve the right to close your account. You acknowledge that
              this action may result in the permanent termination of your access
              to the App, the loss of any virtual credits, other account-related
              benefits, and the forfeiture of any profits or winnings accrued in
              connection with your account. In the event that your account is
              closed, Parlaye will return all remaining deposits.{" "}
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9.3 Failure to Comply
            </h2>
            <p>
              Failure to comply with any of the terms, rules, or guidelines
              outlined in these Terms may result in the closure of your account
              by Parlaye. This includes, but is not limited to, violations
              related to fraudulent activity, unauthorized use of the App,
              breach of intellectual property rights, or engaging in any form of
              misconduct.{" "}
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9.4 Notification
            </h2>
            <p>
              In the event of an account closure, Parlaye will make reasonable
              efforts to notify you of the closure and the reasons behind it,
              unless legally prohibited from doing so.{" "}
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              9.5 Termination of Access
            </h2>
            <p>
              The closure of your account in accordance with this section may
              result in the termination of your access to the App and its
              related services. You will no longer be able to participate in
              contests, access account-related information, or engage in any
              activities associated with the App. Please be aware that Parlaye
              reserves the right to exercise these actions at its sole
              discretion, without prior notice, and without any liability for
              any losses or damages incurred as a result of such account closure
              or forfeiture of profits. In the event that your account is
              closed, Parlaye will return all remaining deposits.
            </p>
          </section>
          {/* end */}

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10 Account Funds
            </h2>
            <p>
              Regardless of the jurisdiction in which you are physically
              located, all deposits, withdrawals, account funds, contest entry
              fees, and prizes are denominated in United States Dollars (USD).
              It is your responsibility to ensure that you have accurately
              designated the appropriate country when conducting deposits and
              withdrawals. We strongly recommend that you do so in order to
              mitigate potential exchange rate fees. Notably, all user account
              funds are securely held in a segregated bank account, distinct
              from Parlaye's operational accounts. This arrangement safeguards
              player funds, ensuring their protection and facilitating
              convenient access in the event you wish to make a withdrawal or
              close your account.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10.1 Deposits & Withdrawals
            </h2>
            <p>
              Parlaye uses third-party payment processors to facilitate deposits
              and withdrawals. Financial data provided by you is secured by such
              third-party payment processors. You agree that Parlaye is not
              liable for the security of this information and is not liable for
              any losses caused by authorized use of the payment methods you
              have used on the App. By accepting the terms of use, users also
              consent to pay for any additional service charges determined by
              our payment processor, which may vary based on country and region.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10.1.1 Deposits
            </h2>
            <p>
              Your depositing capabilities are subject to certain limitations.
              You are restricted to depositing $7,500 USD on a daily basis and
              minimum deposits are $20 USD.{" "}
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10.1.2 Withdrawals
            </h2>
            <p>
              You are restricted to withdraw $7,500 USD on a daily basis and
              minimum withdrawals are $20 USD. Prior to processing withdrawals,
              Parlaye may conduct checks to ensure compliance with the terms and
              conditions set forth in these Terms. It is important to note that
              all deposited funds, including deposit bonuses, must be played
              through before initiating a withdrawal.
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10.1.3 Deposit Bonuses
            </h2>
            <p>
              Deposit bonuses received on the App from Parlaye are
              non-withdrawable funds and must be “played through ” before
              deposited funds are considered withdrawable. Deposited funds or
              deposit bonuses are only considered “played through” when they
              have been wagered in completed contests. Should you have any
              questions, please contact support@parlaye.com.
            </p>
          </section>
          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              10.2 Refund Policy
            </h2>
            <p>
              All entry fees associated to contests are final and no refunds
              will be issued.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">11 Taxes</h2>
            <p>
              The responsibility for filing and remitting any relevant taxes on
              winnings derived from utilizing the App rests solely with you. In
              the event that your winnings, net of all entry fees, amount to
              $600 or more in the previous year, Parlaye may request updated
              address and social security details from you.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              12 Chargebacks
            </h2>
            <p>
              In the event that any of your deposits are subject to chargebacks,
              Parlaye retains the right to promptly suspend or terminate your
              account without prior notice. All contest winnings obtained from
              such charged back funds shall be deducted from your account
              balance.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              13 Technical Malfunctions
            </h2>
            <p>
              Parlaye is not liable for technical malfunctions that may occur,
              which could result in disruptions or interruptions to the App's
              operation. In such cases, Parlaye is not to be held liable for any
              damages or losses incurred by users. While Parlaye endeavors to
              maintain a seamless user experience, factors beyond our control,
              such as system failures, network issues, software glitches,
              maintenance activities, or unforeseen circumstances, may cause
              temporary disruptions. In the event of a technical malfunction,
              Parlaye will make reasonable efforts to resolve the issue
              promptly. However, we cannot guarantee immediate resolution or
              uninterrupted availability of the App.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">14 Indemnity</h2>
            <p>
              You agree to indemnify, defend, and hold harmless Parlaye, its
              affiliates, directors, officers, employees, agents, and licensors
              from and against any claims, liabilities, damages, losses, costs,
              expenses, or demands, including reasonable attorneys' fees,
              arising out of or related to your use of the App or any violation
              of these Terms.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              15 Marketing & Publicity Cooperation
            </h2>
            <p>
              Where legal, you grant Parlaye the right to use your username, and
              any content you post or submit on the App for marketing and
              promotional purposes. This includes, but is not limited to,
              featuring your username, winner announcements, and promotional
              materials related to the App.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              16 Responsible Play
            </h2>
            <p>
              Parlaye is committed to promoting responsible gaming practices to
              ensure a safe and enjoyable experience for all users of the App.
              To ensure appropriate use of the App, we encourage users to set
              responsible play limits or to self-exclude yourself from the App.
              Parlaye reserves the right to determine if you are using the app
              responsibly and based on your user behaviour and playing patterns
              might deem you ineligible from participating in Parlaye contests.{" "}
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              17 User Generated Content
            </h2>
            <p>
              By using the App, you acknowledge and agree that any content you
              generate, upload, post, transmit, or otherwise make available on
              the App (referred to as "User Generated Content") remains your
              sole responsibility. You understand that Parlaye is not
              responsible for any of the User Generated Content on the app but
              has the right to remove such content from the App. Parlaye has
              implemented certain safeguards to identify and address User
              Generated Content that may cause offense to others. However, we
              encourage users to promptly report any instances that may have
              eluded our monitoring measures by contacting us.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              18 Parlaye Intellectual Property
            </h2>
            <p>
              The Parlaye mobile application and website, including all content
              and materials therein (collectively referred to as the
              "Platform"), are owned and operated by Parlaye Inc. All
              intellectual property rights in the Platform, including but not
              limited to copyrights, trademarks, trade secrets, and any other
              proprietary rights, are owned by Parlaye or its licensors. You
              agree not to engage in any activities that may infringe or violate
              the intellectual property rights of Parlaye or any third party.
              This includes, but is not limited to, copying, modifying,
              distributing, transmitting, displaying, performing, or creating
              derivative works of the Platform or any content contained therein,
              without the prior written consent of Parlaye.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              19 Copyright Policy
            </h2>
            <p>
              If you believe that any content available on the App infringes
              your copyright, you may submit a written notification in
              accordance with the provisions of the Digital Millennium Copyright
              Act (DMCA). Your notification should include:
            </p>
            <div className="space-y-2 pl-4">
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  A physical or electronic signature of the copyright owner or a
                  person authorised to act on their behalf;
                </li>
                <li>
                  Identification of the copyrighted work claimed to have been
                  infringed, or, if multiple copyrighted works are covered by a
                  single notification, a representative list of such works;
                </li>
                <li>
                  Identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and
                  that is to be removed or access to which is to be disabled,
                  along with information reasonably sufficient to locate the
                  material;
                </li>
                <li>
                  Information reasonably sufficient to contact the copyright
                  owner, such as an address, telephone number, or email address;
                </li>
                <li>
                  A statement that you have a good faith belief that use of the
                  material in the manner complained of is not authorised by the
                  copyright owner, its agent, or the law; and
                </li>
                <li>
                  A statement that the information in the notification is
                  accurate, and under penalty of perjury, that you are
                  authorised to act on behalf of the copyright owner.
                </li>

                <p>
                  Please send your copyright infringement notification to our
                  designated Copyright Agent at the following address:
                  support@parlaye.com{" "}
                </p>
              </ul>
            </div>
            <p>
              If you believe that content you posted on our platform was removed
              or access to it was disabled as a result of a mistake or
              misidentification, you may submit a counter-notification in
              accordance with the DMCA. Your counter-notification should
              include:
            </p>
            <div className="space-y-2 pl-4">
              <ul className="list-disc pl-6 space-y-2">
                <li>Your physical or electronic signature;</li>
                <li>
                  Identification of the material that has been removed or to
                  which access has been disabled and the location at which the
                  material appeared before it was removed or access to it was
                  disabled;
                </li>
                <li>
                  A statement under penalty of perjury that you have a good
                  faith belief that the material was removed or disabled as a
                  result of mistake or misidentification of the material;
                </li>
                <li>
                  Your name, address, telephone number, and email address; and
                </li>
                <li>
                  A statement that you consent to the jurisdiction of the
                  federal district court for the judicial district in which your
                  address is located, or if your address is outside the United
                  States, for any judicial district in which the service
                  provider may be found, and that you will accept service of
                  process from the person who provided the original copyright
                  infringement notification or an agent of such person.
                </li>
              </ul>
            </div>
            <p>
              Please send your counter-notification to our designated Copyright
              Agent at the address provided in the above section. Parlaye
              reserves the right to terminate, in appropriate circumstances, the
              accounts of users who are repeat infringers of copyright.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              20 Disclaimer of Warranty{" "}
            </h2>
            <p>
              The App and all content and materials therein are provided on an
              "as is" and "as available" basis. Parlaye makes no representations
              or warranties of any kind, whether express, implied, or statutory,
              regarding the App, its content, or any services provided therein.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              21 Limitation of Liability{" "}
            </h2>
            <p>
              To the maximum extent permitted by applicable law, in no event
              shall Parlaye Inc., its directors, officers, employees, or agents
              be liable for any indirect, incidental, special, consequential, or
              punitive damages, including without limitation, loss of profits,
              data, or use, arising out of or in connection with your use of the
              Platform, even if Parlaye has been advised of the possibility of
              such damages.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              22 Links to Third Party Websites
            </h2>
            <p>
              The Platform may contain links to third-party websites,
              applications, or resources that are not controlled or operated by
              Parlaye. Parlaye is not responsible for the availability, content,
              or accuracy of such third-party resources. Your use of third-party
              resources is subject to their respective terms of use and privacy
              policies.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              23 Governing Law and Choice of Forum
            </h2>
            <p>
              The App and these Terms will be governed by and construed in
              accordance with the laws of the Province of Quebec and the federal
              laws of Canada applicable therein, without giving effect to any
              choice or conflict of law provision, principle, or rule (whether
              of the laws of the Province of Quebec or any other jurisdiction)
              and notwithstanding your domicile, residence, or physical
              location. Any permitted court action or proceeding arising out of
              or relating to this App and under these Terms, or related to the
              arbitration process set out below, will be instituted in the
              courts of the Province of Quebec, and each party irrevocably
              submits to the exclusive jurisdiction of such courts in any such
              action or proceeding. You waive any and all objections to the
              exercise of jurisdiction over you by such courts and to the venue
              of such courts.
            </p>
          </section>

          <h2 className="text-2xl font-semibold text-white">
            24 Dispute Resolution
          </h2>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              24.1 Informal Resolution
            </h2>
            <p>
              In the event of any dispute or claim arising from the use of the
              App, Users agree to first attempt to resolve the matter
              informally. Users shall provide written notice to Parlaye Inc.
              outlining the nature of the dispute and the desired resolution by
              filling out a form on parlaye.com/Contact or contacting us at
              support@parlaye.com. Parlaye will review the notice and make good
              faith efforts to resolve the dispute within a reasonable
              timeframe.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              24.2 Binding Arbitration
            </h2>
            <p>
              If an informal resolution cannot be reached, Users agree that any
              dispute, claim, or controversy arising out of or relating to the
              use of the App shall be settled exclusively through binding
              arbitration. The arbitrator's decision shall be final and binding
              on both parties.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">24.3 Location</h2>
            <p>
              The laws of Quebec, Canada, shall govern the arbitration process
              for users accessing the App, regardless of the User’s geographic
              location within the United States or Canada. The arbitration shall
              be conducted in accordance with Quebec law and where applicable as
              mutually agreed upon by the parties.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              24.4 Class Action Waiver
            </h2>
            <p>
              By using the App, users acknowledge and agree that they waive
              their right to participate in any class action lawsuits or
              class-wide arbitration against Parlaye. Users understand that
              disputes will be resolved on an individual basis through binding
              arbitration.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              25 No Third Party Beneficiaries
            </h2>
            <p>
              Except as expressly provided in these Terms, you acknowledge that
              there will be no individuals or parties other than those directly
              involved in these Terms who will benefit from them.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              26 Waiver of Terms
            </h2>
            <p>
              You understand and agree that any failure by Parlaye to enforce
              strict compliance with any provision of the Terms shall not
              constitute a waiver of its right to subsequently enforce such
              provision or any other provision.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">
              27 Force Majeure
            </h2>
            <p>
              Parlaye shall not be held liable for any failure or delay in the
              performance of its obligations under these Terms of Use if such
              failure or delay is due to events beyond its reasonable control,
              including but not limited to acts of God, hurricanes, wars, fires,
              riots, earthquakes, terrorism, actions of public enemies, malware,
              phishing, DoS hacking, data breach or governmental actions outside
              of Parlaye's control (except for compliance with applicable codes
              and regulations), or any other force majeure event Force Majeure
              Event.
            </p>
          </section>

          <section className="space-y-4">
            <h2 className="text-2xl font-semibold text-white">28 Contact Us</h2>
            <p>
              If you have any questions with respect to the terms set out in
              these Terms, please contact us at support@parlaye.com.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
