import React from "react";
import HeroHeader from "../components/HeroHeader";
import Step from "../components/Step";
import AppStore from "../images/App_Store.png";
import downloadImage from "../images/download-image.png";
import PlayStore from "../images/Google_Play.png";
import h2h from "../images/h2h-bubble.png";
import lowervig from "../images/lowervig-bubble.png";
import simplepayout from "../images/simplepayout-bubble.png";
import DiscordIcon from "../images/socials/icon-discord.png";
import InstagramIcon from "../images/socials/icon-instagram.png";
import LinkedinIcon from "../images/socials/icon-linkedIn.png";
import TiktokIcon from "../images/socials/icon-tiktok.png";
import XIcon from "../images/socials/icon-x.png";
import Step1 from "../images/step-1.png";
import Step2 from "../images/step-2.png";
import Step3 from "../images/step-3.png";
import ProductImage from "../images/product-image.png";

export default function Home() {
  return (
    <div
      className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8 py-12 flex flex-col items-center justify-center"
      id="home"
    >
      <div className="flex flex-col items-center justify-center mb-28 gap-20 mt-20">
        <div
          className="w-full bg-black bg-dot-white/[0.2]  relative flex items-center justify-center"
          id="about"
        >
          <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,#084F34)]"></div>
          <HeroHeader
            title="Built by Users for Users"
            subtitle="DFS contests that eliminate the need for perfect parlays. Start playing against your friends today or get matched with opponents in peer-to-peer contests."
          />
        </div>
        <div className="relative w-full flex items-center justify-center">
          <img
            src={ProductImage}
            className="w-[30.45rem] sm:w-[40.6rem] md:w-[50.75rem] h-auto"
          />
          <img
            src={h2h}
            className="absolute w-48 md:w-64 lg:w-80 top-[-4rem] left-[10%] md:left-[5%] animate-float-slow z-10"
            alt="Head to head bubble"
          />
          <img
            src={lowervig}
            className="absolute w-48 md:w-64 lg:w-80 bottom-[-4rem] right-[10%] md:right-[5%] animate-float-medium z-10"
            alt="Lower vig bubble"
          />
          <img
            src={simplepayout}
            className="absolute w-48 md:w-64 lg:w-80 top-[20%] right-[5%] md:right-[15%] animate-float-fast z-10"
            alt="Simple payout bubble"
          />
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-center mb-28 gap-20 w-full"
        id="how-it-works"
      >
        <HeroHeader
          roundedText="PLAY. COMPETE. WIN."
          title="HOW IT WORKS"
          subtitle="We bring players together in exciting peer-to-peer contests, making every match unforgettable."
          variant="secondary"
        />
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-4 sm:gap-6 lg:gap-8 items-stretch">
          <Step
            stepNumber={1}
            heading="Find Players"
            subheading="Pick at least 3 of your favorite player props. Simply pick over & under. Mix and match multiple sports."
            image={Step1}
          />
          <Step
            stepNumber={2}
            heading="Get Matched"
            subheading="As the first curated daily fantasy sports app, we handpick your opponents to maximize entertainment."
            image={Step2}
          />
          <Step
            stepNumber={3}
            heading="Track Parlays"
            subheading={
              <>
                Say goodbye to perfection in parlays. Just because you miss a
                pick, doesn't mean you can't beat your opponent.
                <br />
                You invested in this parlay, it should entertain you longer than
                the time it takes for a single pick to fail.
              </>
            }
            image={Step3}
          />
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-center mb-28 gap-20"
        id="contact"
      >
        <HeroHeader
          roundedText="STAY CONNECTED"
          title="FOLLOW US"
          subtitle="Follow us for updates, tips, and exclusive challenges. Be part of the Parlay community!"
          variant="secondary"
        />
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 sm:gap-12 md:gap-16 w-full max-w-4xl px-4">
          <a
            href="https://discord.gg/gHqZVnR8CB"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center transform hover:scale-110 transition-transform duration-200"
          >
            <img
              src={DiscordIcon}
              alt="Discord"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            />
          </a>
          <a
            href="https://www.instagram.com/parlayeofficial/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center transform hover:scale-110 transition-transform duration-200"
          >
            <img
              src={InstagramIcon}
              alt="Instagram"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/parlaye-inc/about/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center transform hover:scale-110 transition-transform duration-200"
          >
            <img
              src={LinkedinIcon}
              alt="LinkedIn"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            />
          </a>
          <a
            href="https://www.tiktok.com/@parlaye_official"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center transform hover:scale-110 transition-transform duration-200"
          >
            <img
              src={TiktokIcon}
              alt="TikTok"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            />
          </a>
          <a
            href="https://twitter.com/ParlayeOfficial"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center transform hover:scale-110 transition-transform duration-200"
          >
            <img
              src={XIcon}
              alt="X"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24"
            />
          </a>
        </div>
      </div>
      <div
        className="flex flex-col items-center justify-center mb-28 gap-20"
        id="download"
      >
        <HeroHeader
          title="AVAILABLE NOW"
          subtitle="Download the Parlaye App today on the App Store or Google Play Store!"
          variant="image"
          image={downloadImage}
        />
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-4 md:gap-8">
          <a
            href="https://apps.apple.com/us/app/parlaye/id1584562998"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center border-4 border-white rounded-xl overflow-hidden"
          >
            <img src={AppStore} alt="TikTok" className="h-16" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.parlaye.parlaye"
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center border-4 border-white rounded-xl overflow-hidden"
          >
            <img src={PlayStore} alt="X" className="h-16" />
          </a>
        </div>
      </div>
    </div>
  );
}
