import React from "react";
import { useState, useEffect } from 'react';
import { Route } from "react-router-dom";
import CookieConsent from "./components/CookieConsent";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Gleap from 'gleap';

export default function App() {

  useEffect(() => {
    Gleap.initialize("ADURKP8V4vVv8iuTKXua9m0xU4THI3i7");

  }, [])

  return (
    <body className={` bg-black text-gray-100`}>
      <Navbar />
      <Route exact path="/" component={Home} />
      <Route exact path="/TermsOfUse" component={TermsOfUse} />
      <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route exact path="/About" component={About} />
      <Footer />
      <CookieConsent />
    </body>
  );
}
