import React from "react";
import { Link } from "react-router-dom";
import banner from '../images/bannerlogo_resize.svg'

export default function Logo() {
  return (
    <Link to="/" className="flex items-center">
      <img
        src={banner}
        alt="Parlaye Logo"
        width={120}
        height={40}
        className="w-32 h-auto"
      />
    </Link>
  );
}
