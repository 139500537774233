import React from "react";
import { useEffect } from "react";
import contact_1 from "../images/Contact_1.png";
import contact_2 from "../images/Contact_2.png";
import contact_3 from "../images/Contact_3.png";

export default function About() {
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0));
  }, []);

  const features = [
    {
      title: "Entertaining",
      description:
        "Our mission is to revolutionize the way people experience fantasy sports. We believe that the key to success in fantasy sports lies in the power of community and head to head play. That's why we've created a mobile application that leverages the power of community to offer our users parlays that never miss which provide maximum entertainment value and rewards.",
      image: contact_1,
    },
    {
      title: "Curated DFS",
      description:
        "We've taken daily fantasy sports to the next level by introducing a unique curation process. Our app will match you with other users based on your respective skill level, ensuring that you are competing against players of similar ability. This creates a fair and competitive environment for all of our users, making it easier for you to be successful.",
      image: contact_2,
    },
    {
      title: "Secure Network",
      description:
        "We are committed to providing our users with a safe and secure platform to enjoy their daily fantasy sports experience. Our state-of-the-art security measures ensure that your information and fantasy activities are protected at all times.",
      image: contact_3,
    },
  ];

  return (
    <div className="bg-black text-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="space-y-24">
          {features.map((feature, index) => (
            <div
              key={feature.title}
              className={`flex flex-col ${
                index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
              } items-center gap-8 lg:gap-16`}
            >
              <div className="w-full lg:w-1/2 space-y-6">
                <h2 className="text-3xl font-bold text-[#19E094]">
                  {feature.title}
                </h2>
                <p className="text-gray-300 leading-relaxed">
                  {feature.description}
                </p>
              </div>
              <div className="w-full lg:w-1/2 flex items-center justify-center">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-2/3 h-auto rounded-lg shadow-xl"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
