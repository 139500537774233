"use client";

import { useState, useEffect } from "react";
import { Button } from "./ui/button";

export default function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (consentGiven === null) {
      setShowBanner(true);
      // Prevent scrolling when banner is shown
      document.body.style.overflow = "hidden";
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false);
    document.body.style.overflow = "auto";
  };

  const handleReject = () => {
    localStorage.setItem("cookieConsent", "rejected");
    setShowBanner(false);
    document.body.style.overflow = "auto";
  };

  if (!showBanner) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-[#111111] p-6 rounded-lg shadow-lg w-full max-w-lg mx-4 text-white">
        <p className="mb-4 text-sm">
          We use cookies to enhance your browsing experience and analyze our
          traffic. By clicking &quot;Accept&quot;, you consent to our use of
          cookies.
        </p>
        <div className="flex justify-end gap-4">
          <Button
            variant="outline"
            onClick={handleReject}
            className="border-[#19E094] text-[#19E094] hover:bg-[#19E094] hover:text-black"
          >
            Reject
          </Button>
          <Button
            onClick={handleAccept}
            className="bg-[#19E094] text-black hover:bg-[#15B477]"
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
}